import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import NotificationAlert, { PropsNoti } from '../components/notification/NotificationAlert'

type Props = {}

export default function QuizCyber({ }: Props) {
  //set up popup thông báo
  const [openNoti, setOpenNoti] = useState(false)
  const [contentPopup, setContentPopup] = useState<Omit<PropsNoti, 'openNoti'>>({
    messageTitle: '',
    content: '',
    status: "info"
  })
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    // if (localStorage.getItem('email') == 'null' || localStorage.getItem('email') == undefined) {
    // setOpenNoti(true)
    // setContentPopup({
    //   ...contentPopup,
    //   messageTitle: 'Thông báo',
    //   content: 'Bạn chưa đăng ký làm bài kiểm tra. Vui lòng đăng ký để làm bài kiểm tra',
    //   status: 'error'
    // })
    if (location.state?.referrer == null) {
      // navigate('/register')
      window.location.href = 'https://cybersoft.edu.vn'

    }
    // setTimeout(() => {
    //   // navigate('/register')
    // }, 2000);
    // }
  }, [location])

  return (
    <>
      <NotificationAlert
        openNoti={openNoti}
        messageTitle={contentPopup.messageTitle}
        content={contentPopup.content}
        status={contentPopup.status}
      />
      <Outlet />
    </>
  )
}