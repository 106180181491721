import { Form, Input, Modal, Radio, Space } from 'antd';
import React, { createRef, useEffect, useRef, useState } from 'react'
import ButtonCustom from '../button/Button';
import HtmlParser from 'react-html-parser';
import parse from "html-react-parser";
import allQuizTest from './../../tracNghiem.json'
import Countdown, { CountdownRendererFn } from 'react-countdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiQuiz, TypeOfQuestion } from '../../types/types';
import answerQuiz from './../../correctAnswer.json';
import axios from 'axios';
import useScrollPosition from '../../hooks/useScroll';
import CountdownCustom from '../countdown/Countdown';
import NotificationAlert from '../notification/NotificationAlert';


type Props = {
  children?: React.ReactNode
  userId: string
}


const FormQuiz = ({ children, userId }: Props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const scrollPosition = useScrollPosition();
  const [quizs, setQuizs] = useState<ApiQuiz[]>([])
  const [formData, setFormData] = useState<any>([])
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isModalStart, setIsModalStart] = useState(false);
  const location = useLocation();

  const handleCancelStart = () => {
    setIsModalStart(false)
  }

  useEffect(() => {
    setIsModalStart(true)
  }, [])

  useEffect(() => {

  }, [])



  useEffect(() => {
    setQuizs((allQuizTest as ApiQuiz[]))
  }, [])

  const handleChangeCheckBox = (e: any) => {
    const { name, value } = e.target;
    const findIndexData = formData.findIndex((item: any) => item.questionId == name);
    if (findIndexData !== -1) {
      formData[findIndexData].answer = value
      setFormData([...formData])
    } else {
      formData.push({ questionId: Number(name), answer: value, userId })
      setFormData([...formData])
    }
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const findIndexData = formData.findIndex((item: any) => item.questionId == name);
    if (findIndexData !== -1) {
      formData[findIndexData].answer = value.trim();
      setFormData([...formData])
    } else {
      formData.push({ questionId: Number(name), answer: value.trim(), userId });
      setFormData([...formData]);
    }
  }

  const handleSubmit = async () => {
    const correctAnswer = answerQuiz as any;
    let count = 0;
    await formData.forEach((item: any) => {
      const findIndex = correctAnswer.findIndex((correct: any) => correct.id == item.questionId && correct.value == item.answer)
      if (findIndex !== -1) {
        count++;
      }
    })
    const emailUser = localStorage.getItem('email');
    const hoTenUser = localStorage.getItem('hoTen');
    const idUser = localStorage.getItem('idUser');
    const soDienThoaiUser = localStorage.getItem('soDienThoai');
    const result = await axios({
      method: 'POST',
      url: 'https://backend.cyberlearn.vn/api/hopthu/sendmail/2',
      data: {
        "id": 0,
        "tieuDe": `Thông báo về kết quả làm bài test đánh giá năng lực của ${hoTenUser}`,
        "noiDung": `<div style="background-image: url('https://cybersoft.edu.vn/wp-content/uploads/2022/12/backgroundYellow.jpg'); padding:20px;">
        <div style="width: 600px;margin: 0 auto; border-radius: 20px; background-color: white; padding: 30px;">
          <a href="https://cybersoft.edu.vn">
            <img src="https://cybersoft.edu.vn/wp-content/uploads/2022/10/MIN-OP1-2.png" alt="" />
          </a>
          <img src='https://cybersoft.edu.vn/wp-content/uploads/2022/12/trụ-sở-HCM.jpg' style='width:100%;border-radius: 20px;margin-top:15px;object-fit: cover;' alt='img' />
          <p>Xin chào <b>${hoTenUser}!</b></p>
          <p>Cảm ơn bạn đã tham gia bài kiểm tra đánh giá năng lực, bạn đã đạt được số điểm là <b>${count}</b>, CyberSoft sẽ liên hệ bạn qua số điện thoại hoặc email để tư vấn về khoá học phù hợp với bạn, nên hãy chú ý điện thoại nhé</p>
          <p>Hãy theo dõi các email của chúng tôi, các trang web <a href="https://cybersoft.edu.vn/">CyberSoft</a> - <a
              href="https://cyberlearn.vn/">CyberLearn</a> - <a href="https://cyberlab.edu.vn/">CybeLab</a> - <a href="https://www.facebook.com/lophocviet" className='text-blue-700 py-2 px-1 '>Fanpage</a> để biết thêm thông tin về các khóa học của chúng tôi</p>
              <footer>
              <p style="margin-bottom:0px;">Trân trọng,</p>
              <p>CyberSoft</p>
            </footer>
        </div>
      </div>`,
        "hinhAnh": "",
        "chayChinh": true,
        "danhSachEmail": [
          emailUser
        ]
      }
    })
    const loaiForm = "test-dinh-huong"
    const diaDiem = `điểm số: ${count}, trạng thái ${count > 7 ? "Đạt" : "Không Đạt"}`
    const saveData = await axios({
      method: "PUT",
      url: `https://apicrm.cybersoft.edu.vn/api/leadform/${idUser}`,
      data: {
        hoTen: hoTenUser,
        email: emailUser,
        soDT: `0${soDienThoaiUser}`,
        diaDiem,
        loaiForm,
        ngayTao: new Date(),
      },
      headers: {
        Authorization:
          "Bearer " +
          "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyZWQiOiIxMC8xNy8yMDQ1IDExOjI3OjA2IEFNIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImI5Mzc2NDk3LTE2NzgtNDJlMC1iOGY4LWQ1Y2NhYTAzMjc0ZiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImFkbWluQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJDX0tIIiwiQU5ZIiwiQ19MTCIsIkNfTE9QIiwiQ19ORCIsIkNIRUNLX01FTlRPUl9MT1AiLCJEX0RBTkgiLCJEX0tIIiwiRF9MTCIsIkRfTkQiLCJGX0dDIiwiRl9MT1AiLCJHRF9MSCIsIktfVFQiLCJOX1FVWUVOIiwiUUxfQkwiLCJRTF9CTSIsIlFMX0NMIiwiUUxfR0MiLCJRTF9ITVQiLCJRTF9LSCIsIlFMX0xUIiwiUUxfVFQiLCJSX0JIIiwiUl9LSCIsIlJfTEwiLCJSX0xPUCIsIlJfTkQiLCJSX1ZMIiwiVV9LSCIsIlVfTEwiLCJVX0xPUCIsIlVfTkQiLCJYX0tIX0wiLCJRTF9MQ04iLCJRTF9US0QiLCJRTF9DSFRMIiwiUUxfUk0iXSwibmJmIjoxNjY1OTgwODI2LCJleHAiOjE2NjU5ODQ0MjZ9.jwNWA-hbWs2sIkfYYdcxPyQC_bQHIfdowEN77Toug2I",
      },
    })
    localStorage.setItem('email', 'null')
    navigate(`/resultQuiz`, { state: { referrer: location.pathname } })
  }



  return (
    <>
      <NotificationAlert
        messageTitle={"Thông báo"}
        openNoti={false}
        status={"success"}
        content="Vui lòng điền các thông tin trước khi làm bài test"
      />
      <Modal footer={false} className="half_full_screen" title="Thông báo thời gian làm" open={isModalStart} onCancel={handleCancelStart} >
        <div className='flex flex-col text-center text-lg py-10 half_screen text-gray-600 font-medium  justify-center items-center w-full '>
          <p className='mb-2'>Bạn có thời gian 25 phút để làm</p>
          <p className='mb-2'>Sau khi làm xong hệ thống sẽ tính điểm và điểm này sẽ được gửi tới bạn thông qua email</p>
          <p className='mb-2'>Chúc bạn làm tốt</p>
          <button onClick={() => {
            setIsModalStart(false)
          }} className='button_gradient rounded py-2 px-4 text-white'>Bắt đầu</button>
        </div>
      </Modal>
      <div className={`w-full mb-5 `}>
        <div className={`totalQuestion flex justify-between w-full ${scrollPosition > 50 ? "fixed top-0 px-3 items-center flex-wrap  sm:px-10 py-4 left-0 bg-white border-b-gray-300 border-b z-10 mx-auto w-1/2!" : ""}  `}>
          <b className={`${scrollPosition < 50 ? "absolute top-5 left-5 " : "w-full sm:w-fit mb-4 sm:mb-0"}`}><a href="https://cybersoft.edu.vn" onClick={() => {
            window.history.replaceState(null, '', '/')
          }}><i className="fa-solid fa-left-long mr-1"></i> Trở về trang chủ</a></b>
          <p className='font-bold text-lg text-red-600'>Tổng số câu hỏi: {quizs.length}</p>
          {!isModalStart && <CountdownCustom
            modalStart={isModalStart}
            setModalStart={setIsModalStart}
            handleSubmit={handleSubmit}
            isModalOpen={isModalOpen}
          />}

        </div>
      </div>
      <Form form={form} onFinish={handleSubmit} >
        <ul >
          {quizs?.map((item: ApiQuiz, index: number) => {

            const { id, titleQuestion, typeOfQuestion, answers } = item;
            return <li className='mb-14' key={index}>
              <div className='flex text-lg'>
                <b className='inline-block mr-2'>*{`${index + 1})`}</b>
                {HtmlParser(parse(titleQuestion) as any)}
              </div>
              <div>
                {typeOfQuestion == TypeOfQuestion.CheckBox ? <Radio.Group className='mt-5 w-full ' name={String(id)} onChange={handleChangeCheckBox} >
                  <Space direction="vertical" className='w-full'>
                    {answers?.map((answer, index: number) => <Radio className='text-base hover:bg-gray-200 w-full px-3 py-2 rounded' key={index} onChange={handleChangeCheckBox} value={answer.value}>{answer.name}</Radio>)}
                  </Space>
                </Radio.Group>
                  : <div className="content my-8">
                    <Form.Item name={String(id)} rules={[{ required: true, message: 'Vui lòng nhập câu trả lời!' }]} validateTrigger={['onChange', 'onBlur']} >
                      <Input onChange={handleChange} type="text" name={String(id)} id="" className='bg-slate-200 border border-slate-300 py-3 pl-2 pr-5 xl:w-1/3 w-1/2 rounded' />
                    </Form.Item>
                  </div>}
              </div>
            </li>
          })}
        </ul>
        <ButtonCustom className='button_gradient mb-10  py-3 px-10 text-white rounded-3xl  transition-all' type='submit' content='Kết thúc bài làm' />
      </Form>
    </>
  )
}

export default FormQuiz;