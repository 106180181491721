import React, { useState } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { IFormInput } from '../../pages/FormRegister'
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import { useRef } from 'react';
type Props = {
  register: any
  className: string
  placeholder: string
  icon: React.ReactNode
  errors: string | undefined
}

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: '8px',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(145, 158, 171, 0.32)',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
});

export default function CustomInputRegister({ register, className, placeholder, icon, errors }: Props) {
  return (
    <div className='mb-6'>
      <Box className='relative'>
        <CssTextField
          label={placeholder}
          className={className}
          {...register}
          error={errors ? true : false}
        />
        <div className={`absolute right-3 top-1/2 leading-none -translate-y-1/2 icon_input `}>
          {icon}
        </div>
      </Box>
      <p className='text-red-600 text-left mb-2 mt-1'>{errors}</p>
    </div>
  )
}