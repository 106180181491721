import React from 'react';
import './App.css';
import QuizPage from './pages/QuizPage';
import { Route, Routes } from 'react-router-dom';
import DetailQuiz from './pages/DetailQuiz';
import ResultQuiz from './pages/ResultQuiz';
import FormRegister from './pages/FormRegister';
import QuizCyber from './template/QuizCyber';

function App() {
  return (
    <Routes>
      <Route path='' element={<QuizCyber />}>
        {/* <Route path="/detailQuiz" element={<DetailQuiz />} /> */}
      </Route>
      <Route path='/resultQuiz' element={<ResultQuiz />} />
      <Route path='/quiz' index element={<QuizPage />} />
      <Route path='/register' element={<FormRegister />} />
    </Routes >
  );
}

export default App;
