import { Modal } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react'
import ButtonCustom from '../button/Button';
import Countdown from 'react-countdown';

interface CountDownCustomProps {
  modalStart: boolean,
  setModalStart: Dispatch<SetStateAction<boolean>>,
  handleSubmit: any,
  isModalOpen: boolean
}

export default function CountdownCustom({ modalStart, setModalStart, handleSubmit, isModalOpen }: CountDownCustomProps) {
  const [countdown, setCountDown] = useState(Date.now() + 1500000)
  const [flag, setFlag] = useState(false)

  //render cái đồng hồ
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Modal title="Hết giờ" open={isModalOpen} footer={false}>
        <p>Hết giờ làm, bấm ok để đi tiếp</p>
        <ButtonCustom content='Ok' type='submit' className='button_gradient py-2 px-5 rounded text-white' onClick={handleSubmit} />
      </Modal>;
    }
    if (!modalStart && !completed) {
      // Render a countdown
      return <span className='text-lg text-red-600 font-bold'><i className="fa-regular fa-clock"></i> {minutes}:{seconds < 10 ? "0" + seconds : seconds}</span>
    }
  };

  return (
    <>
      <Countdown
        date={countdown}
        autoStart={flag ? false : true}
        renderer={renderer}
        zeroPadTime={2}
      />
    </>

  )
}
