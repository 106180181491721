import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { urlTest } from '../util/setting'
import logo from './../assets/images/cyberlogo.png'
import { notification } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
type Props = {}

const ResultQuiz = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('idUser') == 'null') {
      window.location.href = 'https://cybersoft.edu.vn/bai-kiem-tra-dinh-huong-nghe/'
    }
  }, [])

  useEffect(() => {
    if (location.state?.referrer !== '/quiz') {
      navigate('/register')
    }
  }, [location])


  return (
    <div className='mx-auto sm:w-1/2 w-full p-3'>
      <b className="absolute top-5 left-5 flex items-center"><a href="https://cybersoft.edu.vn"><i className="fa-solid fa-left-long mr-1"></i> Trở về trang chủ</a></b>
      <div className="logo mb-2 mt-12">
        <a href="https://cybersoft.edu.vn">
          <img src={logo} alt="" />
        </a>
      </div>
      <div>
        <h4 className='font-bold'>Cảm ơn bạn đã tham gia Bài kiểm tra đánh giá năng lực của CyberSoft. Bạn sẽ nhận được một email kết quả và CyberSoft sẽ liên hệ để hỗ trợ tư vấn cho bạn.</h4>
        {/* <b className='inline-block mt-3'>Có thêm những câu hỏi ?</b> */}
        <p>Truy cập website <a href="https://cybersoft.edu.vn" className='text-blue-700 py-2 px-1 rounded-3xl  transition-all '>cybersoft.edu.vn</a> để biết thêm thông tin về khoá học đào tạo lập trình và các chương trình ưu đãi của CyberSoft hoặc inbox  <a href="https://www.facebook.com/lophocviet" className='text-blue-700 py-2 px-1 '>fanpage</a>để nhận được tư vấn trực tiếp về các khoá học cũng như định hướng lựa chọn lộ trình học phù hợp cho bạn</p>
      </div>
    </div>
  )
}

export default ResultQuiz;