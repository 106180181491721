import { useEffect } from 'react'
import FormQuiz from '../components/form-quiz/FormQuiz';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from './../assets/images/cyberlogo.png';

type Props = {}


const QuizPage = (props: Props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    if (localStorage.getItem('email') == 'null' || localStorage.getItem('email') == undefined) {
      navigate('/register')
    }
    // console.log(location.state?.referrer)
    if (location.state?.referrer !== '/register') {
      navigate('/register')
    }
  }, [location])




  return (
    <>
      < div className='choiceForm xl:w-1/2 p-10 h-screen w-full mx-auto' >
        <div className="logo mt-5">
          <a href="https://cybersoft.edu.vn">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="titleContent mt-5">
          <h3 className='font-bold text-lg mb-5'>Bài kiểm tra đánh giá năng lực</h3>
          <div className="listQuestion">
            <FormQuiz userId={params.id!} />
          </div>
        </div>
      </div >
    </>
  )
}

export default QuizPage;