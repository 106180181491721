import React, { useEffect, useMemo } from 'react';
import { Button, notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';

export type PropsNoti = {
  messageTitle: string
  content: string
  openNoti: boolean
  status: 'success' | 'info' | 'warning' | 'error'
}
const Context = React.createContext({ name: 'Default' });

export default function NotificationAlert({ messageTitle, content, openNoti, status }: PropsNoti) {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api[status]({
      message: messageTitle,
      description: <Context.Consumer>{() => content}</Context.Consumer>,
    });
  };
  const contextValue = useMemo(() => ({ name: '' }), []);


  useEffect(() => {
    openNoti && openNotification('topRight')
  }, [openNoti])


  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
    </Context.Provider>
  )
}