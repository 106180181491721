export interface ApiQuiz {
  id: number;
  titleQuestion: string;
  typeOfQuestion: TypeOfQuestion;
  answers?: Answer[] | undefined;
}

export interface Answer {
  id: number;
  name: string;
  value: string;
}

export enum TypeOfQuestion {
  CheckBox = "checkBox",
  Field = "field",
}
