import React from 'react'

interface Props {
  className: string,
  content: string,
  type?: "submit" | "button",
  onClick?: any
  disable?: boolean
}

const ButtonCustom = (props: Props) => {
  const { className, content, onClick, disable = false } = props;
  return (
    <>
      <button
        disabled={disable}
        className={className}
        onClick={onClick}
      >
        {content}
      </button>
    </>
  )
}

export default ButtonCustom;