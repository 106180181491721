import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonCustom from '../components/button/Button';
import CustomInputRegister from '../components/customInputRegister';
import axios from 'axios';
import NotificationAlert, { PropsNoti } from '../components/notification/NotificationAlert';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from './../assets/images/logo.png'
import ButtonGoogleCaptcha from '../components/button/ButtonGoogleCaptcha';


type Props = {}

export type IFormInput = {
  hoTen: string
  soDienThoai: string
  email: string
}


//validation
const regexPhoneVietNam = '^(0|84)(2(0[3-9]|1[0-6|8|9]|2[0-2|5-9]|3[2-9]|4[0-9]|5[1|2|4-9]|6[0-3|9]|7[0-7]|8[0-9]|9[0-4|6|7|9])|3[2-9]|5[5|6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])([0-9]{7})$'

const schema = yup.object({
  hoTen: yup.string().trim().required('Không bỏ trống trường này'),
  soDienThoai: yup.number().typeError("Vui lòng nhập vào là sdt").required('Không bỏ trống trường này'),
  email: yup.string().email('Chưa đúng định dạng email').required('Không bỏ trống trường này'),
}).required();


export default function FormRegister({ }: Props) {
  const location = useLocation();

  //set up popup thông báo
  const [openNoti, setOpenNoti] = useState(false)
  const [contentPopup, setContentPopup] = useState<Omit<PropsNoti, 'openNoti'>>({
    messageTitle: '',
    content: '',
    status: "info"
  })

  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors, touchedFields }, reset } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      hoTen: "",
      soDienThoai: "",
      email: "",
    },
    mode: 'all'
  });
  const onSubmit: SubmitHandler<IFormInput> = async (data: any) => {
    const { hoTen, soDienThoai, email } = data

    // console.log(data);
    //Bắt đầu check dữ liệu ng dùng đã làm hoặc chưa
    const res = await axios({
      method: "GET",
      url: `https://apicrm.cybersoft.edu.vn/api/quyen/check-user-he-thong/${data.email}`
    })
    // console.log(res)
    if (res.data.content == 0) {
      setOpenNoti(true)
      setContentPopup({
        ...contentPopup,
        messageTitle: 'Thông báo',
        content: `${res.data.message == "Bạn đã làm bài test rồi !" ? "Email đã đăng ký" : "Bạn đã là học viên nên không cần làm bài test"}, bạn sẽ được chuyển hướng về trang chủ CyberSoft sau 3s`,
      })
      reset()
      setTimeout(() => {
        window.location.href = 'https://cybersoft.edu.vn/'
      }, 2000);
    } else {
      //Bắt đầu gửi dữ liệu lên server
      const result = await axios({
        method: "post",
        url: "https://apicrm.cybersoft.edu.vn/api/leadform",
        data: {
          hoTen,
          email,
          soDT: `0${soDienThoai}`,
          diaDiem: null,
          loaiForm: "test-dinh-huong",
        },
        headers: {
          Authorization:
            "Bearer " +
            "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyZWQiOiIxMC8xNy8yMDQ1IDExOjI3OjA2IEFNIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImI5Mzc2NDk3LTE2NzgtNDJlMC1iOGY4LWQ1Y2NhYTAzMjc0ZiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImFkbWluQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJDX0tIIiwiQU5ZIiwiQ19MTCIsIkNfTE9QIiwiQ19ORCIsIkNIRUNLX01FTlRPUl9MT1AiLCJEX0RBTkgiLCJEX0tIIiwiRF9MTCIsIkRfTkQiLCJGX0dDIiwiRl9MT1AiLCJHRF9MSCIsIktfVFQiLCJOX1FVWUVOIiwiUUxfQkwiLCJRTF9CTSIsIlFMX0NMIiwiUUxfR0MiLCJRTF9ITVQiLCJRTF9LSCIsIlFMX0xUIiwiUUxfVFQiLCJSX0JIIiwiUl9LSCIsIlJfTEwiLCJSX0xPUCIsIlJfTkQiLCJSX1ZMIiwiVV9LSCIsIlVfTEwiLCJVX0xPUCIsIlVfTkQiLCJYX0tIX0wiLCJRTF9MQ04iLCJRTF9US0QiLCJRTF9DSFRMIiwiUUxfUk0iXSwibmJmIjoxNjY1OTgwODI2LCJleHAiOjE2NjU5ODQ0MjZ9.jwNWA-hbWs2sIkfYYdcxPyQC_bQHIfdowEN77Toug2I",
        },
      });
      // console.log(result)
      if (result) {
        localStorage.setItem('hoTen', hoTen);
        localStorage.setItem('email', email);
        localStorage.setItem('soDienThoai', soDienThoai);
        localStorage.setItem('idUser', result.data.content.id);
      }

      setOpenNoti(true)
      setContentPopup({
        ...contentPopup,
        messageTitle: 'Thông báo từ CyberSoft',
        content: 'Bạn sẽ được chuyển hướng đến trang làm bài test',
        status: 'success'
      })
      setTimeout(() => {
        navigate('/quiz', { state: { referrer: location.pathname } })
      }, 2000);

    }



  }

  useEffect(() => {
    localStorage.setItem('email', 'null');

  }, [])


  return (
    <>
      <NotificationAlert
        openNoti={openNoti}
        messageTitle={contentPopup.messageTitle}
        content={contentPopup.content}
        status={contentPopup.status}
      />
      <div className='w-full h-screen flex items-center justify-center bg-purple-layout px-5 sm:px-28 py-7 gap-16'>
        <b className="absolute top-5 left-5 flex items-center text-white"><a href="https://cybersoft.edu.vn"><i className="fa-solid fa-left-long mr-1"></i> Trở về trang chủ</a></b>
        {/* <div className='text-white w-full mb-10 lg:mb-0 space-y-8 lg:w-1/2'>
          <h1 className='text-5xl leading-normal uppercase'>Form điền thông tin cá nhân KIỂM TRA ĐỊNH HƯỚNG NGHỀ LẬP TRÌNH</h1>
          <p className='leading-relaxed font-medium'>CyberSoft tin rằng bất kì ai cũng có thể học lập trình và phát triển bản thân sự nghiệp cho mình. Xu thế công nghệ và chuyển đổi số mang lại nhiều cơ hội, thu nhập tốt dành cho các bạn theo đuổi nghề lập trình. Thông qua bài kiểm tra định hướng này CyberSoft sẽ giúp các bạn lộ trình tối ưu nhất để học tập và đi làm.</p>
          <div className='uppercase flex gap-3'>
            <a href='https://cybersoft.edu.vn/danh-sach-khoa-hoc/' className='button_gradient px-4 py-1'>Khám phá khoá học</a>
            <a href='https://www.facebook.com/lophocviet/' className='text-black px-4 py-1 bg-white mt-3 font-bold'>inbox tư vấn 1-1</a>
          </div>
        </div> */}
        <div className='w-full lg:w-1/2'>
          <div className='flex flex-col bg-white rounded-lg p-11 text-center relative'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="logo sm:block hidden absolute top-7 left-7">
                <a href="https://cybersoft.edu.vn">
                  <img width={70} src={logo} alt="" />
                </a>
              </div>
              <h2 className='uppercase font-bold text-3xl mb-7'>làm bài kiểm tra</h2>
              <CustomInputRegister
                // className='custom_input'
                className='w-full'
                placeholder='Họ và tên'
                register={{ ...register('hoTen') }}
                icon={<i className="fa-solid fa-user"></i>}
                errors={errors.hoTen?.message}
              />
              <CustomInputRegister
                // className='custom_input'
                className='w-full'
                placeholder='Số điện thoại'
                register={{ ...register('soDienThoai') }}
                icon={<i className="fa-solid fa-phone"></i>}
                errors={errors.soDienThoai?.message}
              />
              <CustomInputRegister
                // className='custom_input'
                className='w-full'
                placeholder='Email'
                register={{ ...register('email') }}
                icon={<i className="fa-solid fa-envelope"></i>}
                errors={errors.email?.message}
              />
              <ButtonGoogleCaptcha />
              <ButtonCustom content='Xác nhận' type='submit' className='w-full button_gradient text-white rounded px-3 py-3 text-lg' />
            </form>
          </div>
        </div>
      </div>
      {/* <div className='w-full h-full bg-gray-layout block lg:flex items-start justify-center px-5 sm:px-28 py-10 gap-16'>
        <div className='lg:w-1/2 w-full'>
          <h2 className='text-center font-bold text-5xl leading-normal mb-5'>Qui trình định hướng nghề nghiệp</h2>
          <div className="content pt-10 px-24 pb-5 border border-gray-400 rounded">
            <div className="itemQuiTrinh text-left mb-8 pl-12 relative">
              <p>
                <b className='text-2xl block'>LÀM BÀI KIỂM TRA NĂNG LỰC</b>
                Bạn không cần viết code lập trình. Bài test giúp kiểm tra tư duy logic, khả năng phân tích để CyberSoft hỗ trợ tư vấn định hướng nghề cho bạn.
              </p>
            </div>
            <div className="itemQuiTrinh text-left mb-8 pl-12 relative">
              <p>
                <b className='text-2xl block'>ĐIỀN THÔNG TIN VÀO FORM</b>
                Điền các thông tin vào form làm bài test để CyberSoft có thể liên hệ tư vấn hướng nghiệp cho bạn.
              </p>
            </div>
            <div className="itemQuiTrinh text-left mb-8 pl-12 relative">
              <p>
                <b className='text-2xl block'>NHẬN TƯ VẤN NGAY</b>
                Sau khi bạn hoàn thành bài test, CyberSoft sẽ liên hệ với bạn. Và bạn có thể chủ động <a href="https://www.facebook.com/lophocviet/" target="_blank">inbox</a> trực tiếp cho CyberSoft để được tư vấn 1-1 ngay
              </p>
            </div>
            <div className="itemQuiTrinh text-left mb-8 pl-12 relative">
              <p>
                <b className='text-2xl block'>GHI DANH VÀ HỌC TẬP</b>
                Sau khi đã được tư vấn và xác định rõ ràng về các khoá học giúp bạn bắt đầu sự nghiệp lập trình thì bên tư vấn cũng sẽ hướng dẫn bạn học phí và các đăng ký học
              </p>
            </div>
          </div>
        </div>
        <div className="colRight text-center w-full lg:w-1/2">
          <div className="logo">
            <img className='mx-auto w-1/2' src="https://cybersoft.edu.vn/wp-content/uploads/2022/11/bandochinhanh-02.png" alt="" />
          </div>
          <div className="content mt-5 flex flex-wrap">
            <div className="item w-1/2 mb-5">
              <h4 className='font-bold text-4xl'>8500+</h4>
              <p>HỌC VIÊN</p>
            </div>
            <div className="item w-1/2 mb-5">
              <h4 className='font-bold text-4xl'>~120tr</h4>
              <p>LƯƠNG TRUNG BÌNH/NĂM</p>
            </div>
            <div className="item w-1/2 mb-5">
              <h4 className='font-bold text-4xl'>92%</h4>
              <p>CÓ VIỆC LÀM SAU KHOÁ HỌC</p>
            </div>
            <div className="item w-1/2 mb-5">
              <h4 className='font-bold text-4xl'>200+</h4>
              <p>ĐỐI TÁC</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}