import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

type Props = {}

export default function ButtonGoogleCaptcha({ }: Props) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey='6LfWXrYjAAAAAGjXS9Ze_hc311YttqVO8Mer_giZ'
      language='vi'
    >
    </GoogleReCaptchaProvider>
  )
}